var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "appForm",
        {
          ref: "appForm",
          staticClass: "appform",
          attrs: {
            "to-list": _vm.formList,
            "search-placeholder": "请搜索姓名/工号",
            "screen-roster-list": _vm.screenRosterList,
          },
          on: {
            getSearchCallback: _vm.getSearchCallback,
            screenRosterParams: _vm.screenRosterParams,
          },
        },
        [
          _c(
            "template",
            { slot: "customButton" },
            [
              _c(
                "a-dropdown",
                { attrs: { placement: "bottomLeft" } },
                [
                  _c("a-button", [
                    _vm._v(_vm._s(_vm.importList[_vm.active].title)),
                  ]),
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    _vm._l(
                      _vm.importList[_vm.active].menuList,
                      function (item, index) {
                        return _c(
                          "a-menu-item",
                          {
                            key: index,
                            on: {
                              click: function ($event) {
                                return _vm.importExecl(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.label) + " ")]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          scroll: { x: _vm.active ? "auto" : 2700, y: 480 },
          pagination: {
            current: _vm.pager.pageNum,
            pageSize: _vm.pager.pageSize,
            total: _vm.total,
            showTotal: function (total) {
              return "共 " + total + " 条数据"
            },
            showQuickJumper: true,
            showSizeChanger: true,
          },
          "row-key": "onJobId",
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var scope = ref.scope
              return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
            },
          },
          {
            key: "staffName",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("CbTableAvater", {
                  attrs: {
                    "staff-id": scope.record.staffId,
                    "on-job-id": scope.record.onJobId,
                    "table-name": scope.record.staffName,
                    title: scope.record.staffName,
                    "is-archives": true,
                  },
                }),
              ]
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "a-button",
                  {
                    attrs: { type: "link" },
                    on: {
                      click: function ($event) {
                        return _vm.detail(scope.record)
                      },
                    },
                  },
                  [_vm._v(" 明细 ")]
                ),
              ]
            },
          },
          {
            key: "operationDetail",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "a-button",
                  {
                    attrs: { type: "link" },
                    on: {
                      click: function ($event) {
                        return _vm.detailSupply(scope.record)
                      },
                    },
                  },
                  [_vm._v(" 详情 ")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("DetailDrawer", {
        ref: "detailDrawer",
        attrs: { "detail-type": "company" },
        on: { reloadData: _vm.serchGetData },
      }),
      _c("ImportDrawer", { ref: "importDrawer" }),
      _c("SupplyModal", { ref: "supplyModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }